import React from "react"
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row } from "react-bootstrap"
import * as garmentStyles from "../css/garment.module.css"
import * as homeStyles from "../css/index.module.css"
import { Icon } from '@iconify/react';
import chevronDoubleRight from '@iconify/icons-mdi-light/chevron-double-right';
import { Link } from "gatsby"
import Bgimport from "../components/bgimport"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import PDFImportProduct from "../images/product/mh-catalogue-11.pdf"

const SeoTitle = "สินค้านำเข้า"
const SeoDecription = "สินค้านำเข้า เรานำเข้าสินค้าและจัดหน่ายตามความต้องการของผู้ซื้อ เราคำนึงถึงคุณภาพของสินค้าเป็นอันดับ 1 เพื่อความพึงพอใจของลูกค้าเรา"
const url = "/product-import/"


export default function productimport({data}) {
    return (
    <Containers>
      <Seo 
        title={SeoTitle}
        description={SeoDecription}
        pathname={url}
       />
      <Headertop />
      <Header />
      <Bgimport />
      <div className={garmentStyles.herob}>
      <Container fluid="xl">
              <Row>
                  <Col><p><Link to="/">Home</Link>  <Icon icon={chevronDoubleRight} />  Product</p></Col>
              </Row>
      </Container>
      </div>
      <div className={garmentStyles.herosection}>
          <Container fluid="xl">
              <Row>
                <Col md={3}>
                <div className={garmentStyles.categories}>
                  <h3>หมวดหมู่สินค้า</h3>
                  <Link to="/categories/"><p className={garmentStyles.pmenu}>สินค้าทั้งหมด</p></Link>
                  <Link to="/garment/"><p className={garmentStyles.pmenu}>งานการ์เม้นท์</p></Link>
                  <Link to="/sew-a-sack/"><p className={garmentStyles.pmenu}>ด้ายเย็บกระสอบ</p></Link>
                  <Link to="/industrial-thread/"><p className={garmentStyles.pmenu}>ด้ายอุตสหกรรม</p></Link>
                  <Link to="/product-import/"><p className={garmentStyles.pmenuactive}>สินค้านำเข้า</p></Link>
                  <Link to="/other-products/"><p className={garmentStyles.pmenu}>สินค้าอื่นๆ</p></Link>
                </div>
                </Col>
                <Col md={9}>
                  <Containers>
                    <Row>
                        <Col xs={6} md={4} className={homeStyles.colproduct}>
                          <a href={PDFImportProduct} target="_blank" rel="noreferrer">
                            <StaticImage src="../images/product/import-product.jpg" alt="Import Product" className={homeStyles.imgproduct} />
                            <h3>แค็ตาล็อก อุปกรณ์ตัดเย็บ</h3>
                          </a>
                        </Col>
                    </Row>
                  </Containers>
                </Col>
              </Row>
          </Container>
      </div>
        <Container className="pb-5">
          <Row>
            <Col md={12}>
              <p>นำเข้าอุปกรณ์ตัดเย็บส่วนใหญ่มักจะเป็นเครื่องมือที่ใช้ในการตัดผ้าหรือวัสดุต่าง ๆ และเครื่องจักรที่ใช้ในกระบวนการเย็บ เพื่อช่วยให้การผลิตเป็นไปได้อย่างรวดเร็วและมีคุณภาพ</p>
              <h2>นำเข้าอุปกรณ์ตัดเย็บ มีหลายประเภทของอุปกรณ์ตัดเย็บที่นำเข้า, ได้แก่:</h2>
              <ul>
                <li>เครื่องตัด: เครื่องตัดอาจเป็นมีดหรือเครื่องมือที่ใช้ในกระบวนการตัดผ้าหรือวัสดุต่าง ๆ ตามแบบแผนที่และรูปแบบที่ต้องการ</li>
                <li>จักรเย็บ: นำเข้าจักรเย็บหรือเครื่องเย็บอุตสาหกรรมที่ใช้ในการเย็บผ้าหรือวัสดุต่าง ๆ ในกระบวนการผลิต</li>
                <li>กรรไกรตัดเย็บ: เป็นเครื่องมือที่ใช้ในการตัดเส้นใยหรือด้ายขณะทำงานเย็บ</li>
                <li>กรรไกรตัดผ้า: เครื่องมือที่ใช้ในกระบวนการตัดผ้าเพื่อทำให้มีขนาดและรูปร่างตามที่ต้องการ</li>
                <li>โรลเลอร์: เครื่องมือหรืออุปกรณ์ที่ใช้ในกระบวนการปรับแต่งผ้าหรือวัสดุที่เย็บ</li>
                <li>ไดรเวอร์ Driver: เครื่องมือที่ใช้ในการขับเคลื่อนเครื่องจักรต่าง ๆ ที่ในกระบวนการตัดและเย็บ</li>
              </ul>
              <p>การนำเข้าอุปกรณ์ตัดเย็บทั้งหลายนี้มักเป็นเครื่องมือที่ใช้ในอุตสาหกรรมเสื้อผ้าและการผลิตที่เกี่ยวข้อง, แต่อาจนำเข้าไปใช้ในหลายอุตสาหกรรมอื่น ๆ ที่มีการตัดและเย็บเป็นขั้นตอนสำคัญในกระบวนการผลิต</p>
            </Col>
          </Row>
        </Container>
      <Formfooter />
      <Footer />
    </Containers>
  );
}